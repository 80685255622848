export default {
    namespaced: true,
    state: {
        profil: {
            titre: "Quentin COUTURIER",
            status: "Etudiant en 3ème année de BUT informatique en alternance",
            photo_id: "https://quentin.kilya.net/images/Id_quentin_2022.jpg",
            instances: [
                "Etudiant à l'Université Paul Sabatier de Toulouse",
                "Salarié apprenti à KILYA, l'informatique éthique",
            ],
            description: require("@/assets/content/profile_description.md"),
            headlineProjetPrincipal: "Mon projet du moment :",
            projetMisEnAvant: "orga-basket",
            documents: [
                {
                    icon: "md:file-pdf",
                    name: "Mon CV",
                    file: "CV_quentin-couturier.pdf"
                },
            ],
            contactLines: [
                { type: "Mail", text: "quentin.couturier@orange.fr",
                    clickToAction: {
                        label: "M'envoyer un mail",
                        href: "mailto:quentin.couturier@orange.fr"
                    }
                },
                { type: "Téléphonne", text: "06 43 23 57 26",
                    clickToAction: {
                        label: "Appelez-moi",
                        href: "tel:0643235726"
                    }
                },
                { type: "Dépôt Git", text: "shirosLab",
                    clickToAction: {
                        label: "Venez voir mon code !",
                        href: "https://gitlab.com/shirosLab"
                    }
                },
            ],
        }
    },
    getters: {
        getProfil(state) {
            return state.profil;
        },

    },
    mutations:{
    },
    actions:{
    }
}