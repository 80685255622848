export default {
    namespaced: true,
    state: {
        projets: [
            {
                "name": "comparateur-listes",
                "titre": "Comparateur de listes",
                "headline": "Ça compare des listes ... mais très vite",
                "shortDesc": "Le comparateur de listes est un projet en mathématiques réalisé à l'IUT, plus précisément en qualité algorithmique ! Nous avons implémenté un algorithme en Python qui cherche s'il existe au moins un élément commun parmi deux listes.",
                "imagePrincipale": "https://quentin.kilya.net/images/comparateur-listes/p_comparateur-listes.png",
                "color":"#cba6f7",
                "images": [],
                "langages": [
                  { lang: "Python", link: "https://www.python.org/"}
                ],
                "texte": {
                    "type": "MARKDOWN",
                    "texte": require("@/assets/content/text_comparateur-liste.md")
                },
                "source": {
                    "type": "DEPOT",
                    "link": "https://gitlab.com/projets-iut3/anne-3/comparateur-listes"
                },
                "competences": [
                    {
                        "libelle": "Optimiser",
                        "nom": "Analyser et optimiser des applications",
                        "justification": "Le projet avait pour but de même de nous faire travailler l'optimisation algorithmique."
                    },
                ]
            },
            {
                "name": "git-presenter",
                "titre": "Git presenter",
                "headline": "Un site qui permet de présenter ses dépôts GIT",
                "shortDesc": "Dans le cadre de la formation à l'IUT, chaque étudiant a conçu un portefolio numérique permettant de présenter ses projets. De plus le code est disponible à tous en source ouverte !",
                "imagePrincipale": "https://quentin.kilya.net/images/git-presenter/p_git-presenter.png",
                "color":"#ef9f76",
                "images": [],
                "langages": [
                    { lang: "VueJS", link: "https://vuejs.org/" }
                ],
                "texte": {
                    "type": "MARKDOWN",
                    "texte": require("@/assets/content/text_git-presenter.md")
                },
                "source": {
                    "type": "DEPOT",
                    "link": "https://gitlab.com/shirosLab/git-presenter"
                },
                "competences": [
                    {
                        "libelle": "Réaliser",
                        "nom": "Adapter des applications sur un ensemble de supports",
                        "justification": "Ce projet, tout comme d'autres du genre m'a permis de mettre en application mes compétences."
                    },
                    {
                        "libelle": "Collaborer",
                        "nom": "Identifier ses aptitudes pour travailler dans une équipe",
                        "justification": "Afin d'appliquer ce projet, j'ai utilisé dû analyser mon parcourt et revoir mes projets pour en tirer les compétences."
                    },
                ]
            },
            {
                "name": "nuit-info-iutinfo",
                "titre": "Nuit de l'info 2023",
                "headline": "Marathon de programmation en une nuit",
                "shortDesc": "Durant la nuit de l'info 2023, avec mon équipe, nous avons été invités à coder une application sur le théme de l'environnement entre 20h et 8h le lendemain",
                "imagePrincipale": "https://quentin.kilya.net/images/nuit-info/p_nuit-info.png",
                "color":"#209fb5",
                "images": [],
                "langages": [
                    { lang: "VueJS", link: "https://vuejs.org/" }
                ],
                "texte": {
                    "type": "MARKDOWN",
                    "texte": require("@/assets/content/text_nuit-info.md")
                },
                "source": {
                    "type": "DEPOT",
                    "link": "https://gitlab.com/shirosLab/nuit-info-iutinfo"
                },
                "live": {
                    "type": "WEBSITE",
                    "link": "https://nuit-info.kilya.net"
                },
                "competences": [
                    {
                        "libelle": "Collaborer",
                        "nom": "Manager une équipe informatique",
                        "justification": "Afin de participer à cette nuit, j'ai en grande partie endosser l'effort de créer une équipe en contactant mes camarades et anciens camarades de classe. Ainsi je me suis retrouvé en position naturelle de leader de l'équipe. Bien que celle-ci fonctionnais de manière plutôt autonome, 16h pour un projet c'est peu. Par conséquent j'ai dû appliquer des méthodes de gestion et management pour menner à bien mon équipe."
                    },
                ]
            },
            {
                "name": "orga-basket",
                "titre": "Application de gestion pour club de sport",
                "headline": "Elle permet au club de gérer les rencontres du week-end",
                "shortDesc": "Depuis le début de la saison de basket dans mon club, je suis parti du constat qu'il y avait un manque d'organisation dans la gestion des rencontres : arbitre, table de marque, horaire et lieu.",
                "imagePrincipale": "https://quentin.kilya.net/images/orga-basket/p_orga-basket.png",
                "color":"#ed8796",
                "images": [],
                "langages": [
                    { lang: "VueJS", link: "https://vuejs.org/" },
                    { lang: "Java", link: "https://www.java.com/fr/" },
                    { lang: "Spring", link: "https://spring.io/" }
                ],
                "texte": {
                    "type": "MARKDOWN",
                    "texte": require("@/assets/content/text_orgaBasket.md")
                },
                "source": {
                    "type": "DEPOT",
                    "link": "https://gitlab.com/orga-basket"
                },
                "live": {
                    "type": "WEBSITE",
                    "link": "https://orga-basket.kilya.net"
                },
                "competences": [
                    {
                        "libelle": "Conduire & Collaborer",
                        "nom": "Appliquer une démarche de suivi de projet en fonction des besoins métiers des clients et des utilisateurs",
                        "justification": "Le projet, bien que fonctionnel à du mal à prendre du fait des habitudes à l'ancien systéme et de l'age souvent avancé des utilisateurs concernés. Ainsi j'ai du mettre à profit mes conpétences en communications et en conduction de projet afin de favoriser ces changements"
                    },
                    {
                        "libelle": "Réaliser",
                        "nom": "Adapter des applications sur un ensemble de supports",
                        "justification": "Le fait d'adapter mon interface au téléphone m'as beaucoup fait progresser dans le troisième niveau de la comptétende."
                    },
                ]
            },
            {
                "name": "api-rest",
                "titre": "Un server et un client REST",
                "headline": "A quoi bon utiliser discord alors que j'ai une API-rest !",
                "shortDesc": "En binôme, codage d'un serveur de messagerie en REST ainsi qu'un client pour intéragir avec.",
                "color":"#a6d189",
                "images": [],
                "langages": [
                    { lang: "PHP", link: "https://www.php.net/" }
                ],
                "texte": {
                    "type": "MARKDOWN",
                    "texte": require("@/assets/content/text_api-rest.md")
                },
                "source": {
                    "type": "DEPOT",
                    "link": "https://gitlab.com/projets-iut3/anne-2/apirest"
                },
                "competences": [
                    {
                        "libelle": "Réaliser",
                        "nom": "Adapter des applications sur un ensemble de supports",
                        "justification": "Nous avons appliqué pour la première fois les principes de responcivité dans une application WEB."
                    },
                    {
                        "libelle": "Collaborer",
                        "nom": "Manager une équipe informatique",
                        "justification": "Pour aller plus loin, nous avons communiqué entre équipes afin de savoir comment brancher notre interface sur leurs API. Ces échanges nous ont beaucoup sensibilisé sur l'importance de produire des outils clairs et simples d'utilisation."
                    },
                ]
            },
            {
                "name": "java-wave-function-collapser",
                "titre": "Wave function collapse",
                "headline": "Une implémentation de zéro de l'algorithme de wave function collapse",
                "shortDesc": "Cet algorithme permet de faire de génération procédurale et j'en ai fait une implémentation avec des emojis.",
                "imagePrincipale": "https://quentin.kilya.net/images/java-wave-function-collapser/p_beach.png",
                "color":"#89b4fa",
                "images": [
                ],
                "langages": [
                    { lang: "Java", link: "https://www.java.com/fr/" },
                ],
                "texte": {
                    "type": "MARKDOWN",
                    "texte": require("@/assets/content/text_wave-function-collapse.md")
                },
                "source": {
                    "type": "DEPOT",
                    "link": "https://gitlab.com/projets-persos2/wave-function-collapse"
                },
                "competences": [
                    {
                        "libelle": "Réaliser",
                        "nom": "Adapter des applications sur un ensemble de supports",
                        "justification": "Ma contrainte de temps sur ce projet m'as imposé d'étre inventif pour l'affichage de mes visuels. La solution des emojis parrait bête mais il s'avére que l'on en voit de plus en plus dans les interfaces, parfois même dans les terminaux de commandes (kubernetes/minicube)"
                    },
                    {
                        "libelle": "Optimiser",
                        "nom": "Analyser et optimiser des applications",
                        "justification": "Ce projet et la méthode en 3 temps que j'ai adopté m'ont permis de m'amméliorer en optimisation et en 'clean code'."
                    },
                ]
            },
            {
                "name": "should-i-ship",
                "titre": "Should I ship",
                "headline": "Développement Android imaginé et réalisé en binôme à l'IUT",
                "shortDesc": "Avec un camarade de classe nous avons codé une application Android qui détermine le coût carbone d’une livraison en fonction du mode de transport et de sa masse.",
                "imagePrincipale": "https://quentin.kilya.net/images/should-i-ship/p_shoud-i-ship.png",
                "color":"#e5c890",
                "images": [
                    {
                        "title": "Vue historique 2 articles",
                        "src": "https://quentin.kilya.net/images/should-i-ship/sis-histo_2articles.png"
                    },
                    {
                        "title": "Vue formulaire",
                        "src": "https://quentin.kilya.net/images/should-i-ship/sis_form.png"
                    },
                    {
                        "title": "Vue détaillé",
                        "src": "https://quentin.kilya.net/images/should-i-ship/sis_detail-view.png"
                    },
                    {
                        "title": "Vue historique 3 articles",
                        "src": "https://quentin.kilya.net/images/should-i-ship/sis-histo_3articles.png"
                    },
                ],
                "langages": [
                    { lang: "Java", link: "https://www.java.com/fr/" },
                    { lang: "Android", link: "https://developer.android.com/" },
                ],
                "texte": {
                    "type": "MARKDOWN",
                    "texte": require("@/assets/content/text_should-i-ship.md")
                },
                "source": {
                    "type": "DEPOT",
                    "link": "https://gitlab.com/projets-iut3/anne-2/ShouldIShipBis"
                },
                "competences": [
                    {
                        "libelle": "Réaliser",
                        "nom": "Adapter des applications sur un ensemble de supports",
                        "justification": "Nous avons beaucoup aimé travailler sur ce support. Il ouvre un grand champ des possible, que ce soit l'utilisation de la géolicalisation, de l'appareil photo ou l'aspect disponible quelque soit l'endroit."
                    },
                    {
                        "libelle": "Conduire",
                        "nom": "Appliquer une démarche de suivi de projet en fonction des besoins métiers des clients et des utilisateurs",
                        "justification": "Le développement sur Android était quelque-chose de nouveau nour nous. Les contraintes liés au support et au test des éléments d'interfaces sur nos deux téléphonnes nous ont contraint à nous armer de aptience et de communication pour conduire notre projet jusqu'au bout."
                    },
                ]
            },
        ]
    },
    getters: {
        getProjects(state) {
            return state.projets;
        },
        getProjectById: (state) => (projectName) => {
            return state.projets.find(p => p.name === projectName);
        },
    },
}
