<template>
  <v-card
      variant="elevated"
      elevation="6"
  >
    <v-row class="ma-6">
      <v-col>
        <v-img max-height="35vh" class="border-6" :src="profil.photo_id"></v-img>
      </v-col>
      <v-col align-self="center">
        <v-card-title>{{ profil.titre }}</v-card-title>
        <v-card-subtitle>{{ profil.status }}</v-card-subtitle>
        <v-divider class="ma-2" thickness="3"></v-divider>
        <v-card-text class="text-left">
          <v-label>Actuellement :</v-label>
          <p
              v-for="(instance, i ) in profil.instances" :key="i"
          >{{ instance }}</p>
        </v-card-text>
      </v-col>
    </v-row>
    <v-divider class="ma-2" thickness="3"></v-divider>
    <v-card-text class="text-left text-justify">
      <to-markdown :md-text="profil.description"></to-markdown>
    </v-card-text>
    <v-divider class="ma-2" thickness="3"></v-divider>
    <v-card-text class="text-left">
      <v-label>Contact :</v-label>
      <v-container>
        <v-row
            class="my-1"
            justify="space-between"
          >
          <v-col
              v-for="(ctl, i ) in profil.contactLines" :key="i"
          >
            <v-card width="100%" variant="outlined" color="primary">
              <v-card-subtitle class="my-2" color="primary">
                <v-chip
                    density="compact"
                >{{ ctl.type }}</v-chip> : {{ ctl.text }}
              </v-card-subtitle>
              <v-btn density="compact" v-if="ctl.clickToAction" :href="ctl.clickToAction.href" variant="tonal" block="" target="_blank" color="primary">{{ ctl.clickToAction.label }}</v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <router-link class="rl" to="/historique"><v-btn variant="flat" color="primary">Voir mon parcours</v-btn></router-link>
    </v-card-actions>
  </v-card>
</template>

<script>
import {marked} from "marked";
import ToMarkdown from "@/components/to-markdown.vue";

export default {
  name: "carte-profil",
  components: {ToMarkdown},
  props: {
    profil: {
      type: Object,
      default: 0
    },
  },
  computed: {
    markdownToHtml(){
      return marked.parseInline(this.profil.description);
    },
  },
}
</script>

<style lang="scss" scoped>

</style>